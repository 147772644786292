import clsx from "clsx";
import { iconsByName, classesByName } from "../icon-source";

type SvgProps = React.SVGProps<SVGSVGElement>;

export type IconColors =
	| "primary"
	| "secondary"
	| "inverse"
	| "current"
	| "success"
	| "info"
	| "warning"
	| "arrow"
	| "cta";

interface Props extends SvgProps {
	width?: string;
	height?: string;
	name: IconName;
	color?: IconColors;
	className?: string;
}

export type IconName = keyof typeof iconsByName;

export function Icon({
	width,
	height,
	name,
	color = "current",
	className,
	style,
}: Props) {
	const icon = iconsByName[name];

	const iconColors = {
		primary: "text-theme-primary",
		secondary: "text-grey-60 dark:text-grey-40",
		current: "text-current",
		inverse: "text-inverse",
		success: "text-green-50",
		info: "text-blue-50",
		warning: "text-yellow-50",
		arrow: "text-grey-40",
		cta: "text-primary-80",
	};

	return (
		<svg
			height={height || icon.height}
			width={width || icon.width}
			viewBox={icon.viewBox}
			fill={icon.fill}
			xmlns={"http://www.w3.org/2000/svg"}
			className={clsx(className, classesByName[name], iconColors[color])}
			style={style}
		>
			{icon?.rect && icon.rect}
			{icon?.line && icon.line}
			{icon.path}
		</svg>
	);
}
